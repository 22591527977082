import { Controller } from "@hotwired/stimulus"
import { enter, leave } from "el-transition"

export default class extends Controller {
  static targets = ["container", "backdrop", "panel", "sidebar"]

  disconnect() {
    document.removeEventListener('click', this.handleClick)
  }

  show() {
    this.containerTarget.classList.remove("hidden")
    Promise.all([
      enter(this.backdropTarget),
      enter(this.panelTarget),
      enter(this.sidebarTarget)
    ]).then(() => {
      document.addEventListener('click', this.handleClick)
    })
  }

  hide() {
    Promise.all([
      leave(this.backdropTarget),
      leave(this.panelTarget),
      leave(this.sidebarTarget)
    ]).then(() => {
      this.containerTarget.classList.add("hidden")
      document.removeEventListener('click', this.handleClick)
    })
  }

  handleClick = (e) => {
    console.log("handling click")
    if (!this.panelTarget.contains(e.target)) {
      this.hide()
    }
  }
}