import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ["container"]
  static values = {
    initialQuerySelector: String,
  }

  connect() {
    if (this.hasInitialQuerySelectorValue) {
      let el = document.querySelector(this.initialQuerySelectorValue)
      

      setTimeout(() => {
        this.scrollTo(el)
      }, 600)
    }
  }

  scroll(event) {
    event.preventDefault()
    let href = event.currentTarget.getAttribute("href")

    this.scrollTo(document.querySelector(href))
  }

  scrollTo(el) {
    let container = this.hasContainerTarget ? this.containerTarget : document
    let options = {
      top: this.offsetTop(el),
      behavior: "smooth"
    }

    this.hasContainerTarget ? this.containerTarget.scroll(options) : scroll(options)
  }

  offsetTop(el) {
    let offset = 0
    if (this.hasContainerTarget) {
      offset = el.offsetTop - this.containerTarget.offsetTop
    } else {
      offset = el.offsetTop
    }

    return offset - 16
  }
}
