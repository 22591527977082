import { Controller } from "@hotwired/stimulus";
import ahoy from "ahoy.js";

export default class extends Controller {
  static values = { id: String };

  connect() {
    console.log("Banner controller connected");
    this.observeBannerVisibility();
  }

  observeBannerVisibility() {
    const observer = new IntersectionObserver((entries) => {
      console.log(this.idValue);
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          console.log(this.idValue);
          ahoy.track("Promotion Banner View", { banner_id: this.idValue });
        }
      });
    });

    observer.observe(this.element);
  }

  handleClick() {
    console.log(this.idValue);
    ahoy.track("Promotion Banner Click", { banner_id: this.idValue });
  }
}
