import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["entry"];

  initialize() {
    let options = {
      rootMargin: "100px",
    };

    this.intersectionObserver = new IntersectionObserver(
      (entries, self) => this.processIntersectionEntries(entries, self),
      options
    );
  }

  connect() {
    this.attachInstanceToThisElement();
    this.lazyLoad();
  }

  disconnect() {
    this.entryTargets.forEach((entry) => {
      this.intersectionObserver.unobserve(entry);
    });
  }

  processIntersectionEntries(entries, self) {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        this.loadContentFor(entry.target);
        self.unobserve(entry.target);
      }
    });
  }

  loadContentFor(targetElement) {
    this.preloadImage(targetElement);
  }

  preloadImage(targetElement) {
    const src = targetElement.getAttribute("data-src");
    if (!src) {
      return;
    }
    if (this.isImage(targetElement)) {
      targetElement.addEventListener(
        "load",
        function (event) {
          targetElement.removeAttribute("data-src");
          targetElement.classList.remove("opacity-0");
          targetElement.parentElement.classList.remove("animate-pulse");
          targetElement.parentElement.classList.remove("bg-gray-100");
        },
        { once: true }
      );

      targetElement.src = src;
    } else {
      targetElement.style.backgroundImage = `url("${src}")`;
    }
  }

  needToLoadImageFor(targetElement) {
    return targetElement.hasAttribute("data-src");
  }

  isImage(targetElement) {
    return targetElement instanceof HTMLImageElement;
  }

  lazyLoad() {
    this.entryTargets.forEach((entry) => {
      if (this.needToLoadImageFor(entry)) {
        this.intersectionObserver.observe(entry);
      }
    });
  }

  attachInstanceToThisElement() {
    // camelCase the name of your Stimulus controller
    this.element[
      ((str) => {
        return str
          .split("--")
          .slice(-1)[0]
          .split(/[-_]/)
          .map((w) => w.replace(/./, (m) => m.toUpperCase()))
          .join("")
          .replace(/^\w/, (c) => c.toLowerCase());
      })(this.identifier)
    ] = this;
  }
}
