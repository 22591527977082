import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['email', 'form']
  static values = {
    isModal: Boolean,
    isSubscribed: Boolean,
  }

  connect() {
    if (this.isModalValue) {
      if (this.isOnNewsletterPage()) {
        this.showNewsletterModal()
      } else if (this.isModalValue && !this.isSubscribedValue && !this.popupShownValue) {
        this.initializePopup()
      }
    }
  }

  isOnNewsletterPage() {
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    return urlParams.has('newsletter')
  }

  initializePopup() {
    let self = this
    if (!this.popupRecentlyShown()) {
      // I'm going to row this out in the future.
      if (false && window.innerWidth >= 1024) {
        // bring popup in after mouse goes to exit page
        document.addEventListener("mouseout", function popupOnExitIntent(event) {
          if (event.toElement === null && event.relatedTarget === null) {
            setTimeout(() => {
              self.showNewsletterModal()
            }, 600)
            self.addPopupCookie()
            document.removeEventListener("mouseout", popupOnExitIntent)
          }
        })
      } else {
        // bring popup in after 2 minutes.
        this.popupTimeout = setTimeout(() => {
          self.showNewsletterModal()
          self.addPopupCookie()
        }, 60*1000*2)
      }
    }
  }

  onSubmit(event) {
    console.log(this.formTarget.action)
    event.preventDefault()
    const formData = new FormData(this.formTarget);

    let url = this.formTarget.action + new URLSearchParams(formData).toString()
    console.log(url)
    console.log(this.formTarget.method)
    fetch(url, {
      mode: 'no-cors',
      method: this.formTarget.method,
    })
    .then((res) => {
      console.log(res)
      // Success
      this.handleSuccessfulSubscription()
      this.removePopupTimeout()
    })
    .catch((e) => {
      // Error
      console.log("Error")
    })

    /*
    let httpRequest = new XMLHttpRequest()
    let url = `/newsletter_subscriptions?newsletter_subscription[email]=${this.emailTarget.value}`
    let authToken = this.formTarget.querySelector("input[name='authenticity_token']").value

    httpRequest.responseType = "json"
    httpRequest.open('POST', url)
    httpRequest.setRequestHeader('X-CSRF-Token', authToken)
    let self = this
    httpRequest.onreadystatechange = function() {
			if (httpRequest.readyState === XMLHttpRequest.DONE) {
        self.handleSuccessfulSubscription()
        self.removePopupTimeout()
			}
		}

    httpRequest.send()
    */
  }

  handleSuccessfulSubscription() {
    // Remove form and replace it with alert
    this.formTarget.insertAdjacentHTML('afterend', this.alertTemplate())
    this.formTarget.remove()
  }

  showNewsletterModal() {
    let toggleController = this.application.getControllerForElementAndIdentifier(
      this.element,
      "toggle"
    )

    toggleController.show()
  }

  addPopupCookie() {
    let today = new Date()
    let expire = new Date()

    // expires 14 days from now
    expire.setTime(today.getTime() + 3600000*24*14)
    document.cookie = `popup_shown = true; path=/; expires=${expire.toGMTString()} SameSite=Strict`
  }

  popupRecentlyShown() {
    return document.cookie.split(";").filter(item => item.includes("popup_shown=")).length > 0
  }

  removePopupTimeout() {
    // Stop popup from appearing
    if (this.isModalValue) {
      this.clearPopupTimeout()
    } else {
      let modal = document.getElementById("newsletter-modal")
      if (modal) {
        let newsletterController = this.application.getControllerForElementAndIdentifier(
          modal,
          "newsletter"
        )
  
        newsletterController.clearPopupTimeout()
      }
    }
  }

  clearPopupTimeout() {
    clearTimeout(this.popupTimeout)
    this.popupTimeout = null
  }

  alertTemplate() {
    return `
      <div class="rounded-md bg-teal-50 p-4 max-w-xl mx-auto">
        <div class="flex">
          <div class="flex-shrink-0">
            <!-- Heroicon name: solid/check-circle -->
            <svg class="h-5 w-5 text-teal-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
            </svg>
          </div>
          <div class="ml-3 flex-1 md:flex md:justify-between">
            <p class="text-sm text-teal-700">
              <strong>Thanks for subscribing!</strong> Expect an email from us shortly :)
            </p>
          </div>
        </div>
      </div>
    `
  }
}
