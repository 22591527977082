import Sticky from 'sticky-js'
import { Controller } from '@hotwired/stimulus'

export default class extends Controller {

  initialize() {
    document.documentElement.style.scrollBehavior = 'smooth'
    const sticky = new Sticky("[data-controller='sticky']")
  }
}
