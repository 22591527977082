export const Helpers = {
  toHTMLAttributes: function(data) {
    // only items which are objects have properties which can be used as attributes
		if( Object.prototype.toString.call(data) != "[object Object]" )
			return '';

		var keys = Object.keys(data),
		  s = "", propName, i;

		for( i=keys.length; i--; ){
			propName = keys[i];
			if( propName != 'class' && data.hasOwnProperty(propName) && data[propName] !== undefined )
				s += " " + propName + (data[propName] !== undefined ? `="${data[propName]}"` : "");
		}

		return s;
  },

  currentPageHasTrixEditor: function() {
    let pathname = window.location.pathname
    let pathnameArray = pathname.split("/")
    let isResourceEditPage = (pathnameArray.includes("resources") && pathnameArray.includes("edit"))
    return (pathname === "/resources/new" || isResourceEditPage) ? true : false
  }, 

	/* Port of strftime(). Compatibility notes:
	*
	* %c - formatted string is slightly different
	* %D - not implemented (use "%m/%d/%y" or "%d/%m/%y")
	* %e - space is not added
	* %E - not implemented
	* %h - not implemented (use "%b")
	* %k - space is not added
	* %n - not implemented (use "\n")
	* %O - not implemented
	* %r - not implemented (use "%I:%M:%S %p")
	* %R - not implemented (use "%H:%M")
	* %t - not implemented (use "\t")
	* %T - not implemented (use "%H:%M:%S")
	* %U - not implemented
	* %W - not implemented
	* %+ - not implemented
	* %% - not implemented (use "%")
	*
	* strftime() reference:
	* http://man7.org/linux/man-pages/man3/strftime.3.html
	*
	* Day of year (%j) code based on Joe Orost's answer:
	* http://stackoverflow.com/questions/8619879/javascript-calculate-the-day-of-the-year-1-366
	*
	* Week number (%V) code based on Taco van den Broek's prototype:
	* http://techblog.procurios.nl/k/news/view/33796/14863/calculate-iso-8601-week-and-year-in-javascript.html
	*/
	strftime: function(sFormat, date) {
		if (!(date instanceof Date)) date = new Date();
		var nDay = date.getDay(),
			nDate = date.getDate(),
			nMonth = date.getMonth(),
			nYear = date.getFullYear(),
			nHour = date.getHours(),
			aDays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
			aMonths = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
			aDayCount = [0, 31, 59, 90, 120, 151, 181, 212, 243, 273, 304, 334],
			isLeapYear = function() {
				return (nYear%4===0 && nYear%100!==0) || nYear%400===0;
			},
			getThursday = function() {
				var target = new Date(date);
				target.setDate(nDate - ((nDay+6)%7) + 3);
				return target;
			},
			zeroPad = function(nNum, nPad) {
				return ('' + (Math.pow(10, nPad) + nNum)).slice(1);
			};
		return sFormat.replace(/%[a-z]/gi, function(sMatch) {
			return {
				'%a': aDays[nDay].slice(0,3),
				'%A': aDays[nDay],
				'%b': aMonths[nMonth].slice(0,3),
				'%B': aMonths[nMonth],
				'%c': date.toUTCString(),
				'%C': Math.floor(nYear/100),
				'%d': zeroPad(nDate, 2),
				'%e': nDate,
				'%F': date.toISOString().slice(0,10),
				'%G': getThursday().getFullYear(),
				'%g': ('' + getThursday().getFullYear()).slice(2),
				'%H': zeroPad(nHour, 2),
				'%I': zeroPad((nHour+11)%12 + 1, 2),
				'%j': zeroPad(aDayCount[nMonth] + nDate + ((nMonth>1 && isLeapYear()) ? 1 : 0), 3),
				'%k': '' + nHour,
				'%l': (nHour+11)%12 + 1,
				'%m': zeroPad(nMonth + 1, 2),
				'%M': zeroPad(date.getMinutes(), 2),
				'%p': (nHour<12) ? 'AM' : 'PM',
				'%P': (nHour<12) ? 'am' : 'pm',
				'%s': Math.round(date.getTime()/1000),
				'%S': zeroPad(date.getSeconds(), 2),
				'%u': nDay || 7,
				'%V': (function() {
								var target = getThursday(),
									n1stThu = target.valueOf();
								target.setMonth(0, 1);
								var nJan1 = target.getDay();
								if (nJan1!==4) target.setMonth(0, 1 + ((4-nJan1)+7)%7);
								return zeroPad(1 + Math.ceil((n1stThu-target)/604800000), 2);
							})(),
				'%w': '' + nDay,
				'%x': date.toLocaleDateString(),
				'%X': date.toLocaleTimeString(),
				'%y': ('' + nYear).slice(2),
				'%Y': nYear,
				'%z': date.toTimeString().replace(/.+GMT([+-]\d+).+/, '$1'),
				'%Z': date.toTimeString().replace(/.+\((.+?)\)$/, '$1')
			}[sMatch] || sMatch;
		});
	},
}
