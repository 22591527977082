import { Controller } from "@hotwired/stimulus";
import { get } from "@rails/request.js";

export default class extends Controller {
  static targets = ["next", "masonry"];

  async loadMore(event) {
    event.preventDefault();
    this.addLoadingIndicator();
    const response = await get(this.nextTarget.dataset.nextUrl, {
      responseKind: "turbo-stream",
    });

    if (response.ok) {
      setTimeout(() => {
        this.lazyLoadImages();
        if (this.hasMasonryTarget) {
          this.reinitializeMasonry();
        }
      }, 350);
    }
  }

  reinitializeMasonry() {
    console.log("MASONRY CONTROLLER CALED");
    let masonryController =
      this.application.getControllerForElementAndIdentifier(
        this.masonryTarget,
        "masonry"
      );

    masonryController.reinitialize();
  }

  lazyLoadImages() {
    let lazyLoaderController =
      this.application.getControllerForElementAndIdentifier(
        this.element,
        "lazy-loader"
      );

    lazyLoaderController.lazyLoad();
  }

  addLoadingIndicator() {
    let buttonLoaderController =
      this.application.getControllerForElementAndIdentifier(
        this.nextTarget,
        "button-loader"
      );

    buttonLoaderController.load();
  }

  removeLoadingIndicator() {
    let buttonLoaderController =
      this.application.getControllerForElementAndIdentifier(
        this.nextTarget,
        "button-loader"
      );

    buttonLoaderController.unload();
  }
}
