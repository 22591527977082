import { Controller } from '@hotwired/stimulus'
import { get } from "@rails/request.js"

export default class extends Controller {

  filter = async (event) => {
    var url = event.target.dataset.url
    console.log(url)
    const response = await get(url, {
      responseKind: "turbo-stream"
    })

    console.log(response)
    
    //history.pushState(response.response.body, "", url)
    history.pushState(history.state, "", new URL(url, `${window.location.protocol}//${window.location.host}`))
  }

  resolveNewInputValue(currentValue, checkboxValue, checked) {
    if (!checked) {
      let values = currentValue.split("+")
      return values.length == 1 ? null : values.filter(v => v != checkboxValue).join("+")
    } else if (currentValue) {
      return `${currentValue}+${checkboxValue}`
    } else {
      return checkboxValue
    }
  }
}