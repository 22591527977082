import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ["toggler", "toggleable"]
  static values = {
    activeTogglerClasses: Array,
    inactiveTogglerClasses: Array,
    activeToggleableClasses: Array
  }

  connect() {
    let defaultToggle = this.togglerTargets.filter(toggle => window.getComputedStyle(toggle).display !== "none")[0]
    console.log(defaultToggle)
    this.toggleClasses(defaultToggle)
  }

  toggle(event) {
    this.toggleClasses(event.currentTarget)
  }

  toggleClasses(activeToggle) {
    if (activeToggle.hasAttribute("data-toggle-classes")) {
      this.activeToggleableClassesValue = activeToggle.dataset.toggleClasses.split(" ")
    }
    
    this.togglerTargets.filter(toggle => toggle != activeToggle).forEach(toggle => {
      this.activeTogglerClassesValue.forEach(klass => {
        toggle.classList.remove(klass)
      })

      this.inactiveTogglerClassesValue.forEach(klass => {
        toggle.classList.add(klass)
      })
    })

    this.inactiveTogglerClassesValue.forEach(klass => {
      activeToggle.classList.remove(klass)
    })

    this.activeTogglerClassesValue.forEach(klass => {
      activeToggle.classList.add(klass)
    })
  }

  activeToggleableClassesValueChanged(currentClasses, previousClasses) {
    console.log("Toggle classes")
    if (currentClasses) {
      this.toggleableTargets.forEach(target => {
        currentClasses.forEach(klass => {
          target.classList.add(klass)
        })
      })
    }

    if (previousClasses) {
      this.toggleableTargets.forEach(target => {
        previousClasses.forEach(klass => {
          target.classList.remove(klass)
        })
      })
    }
  }
}