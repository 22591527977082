import { Controller } from '@hotwired/stimulus'
export default class extends Controller {
  static targets = ["content", "chevron"]
  static values = {
    isOpen: Boolean,
  }

  initialize() {
    this.resizeObserver = new ResizeObserver((entries, self) => this.processResizeEntries(entries, self))

  }

  connect() {
    if (this.isOpenValue) {
      setTimeout(() => {
        this.open()
      }, 100)
    }

    this.resizeObserver.observe(this.contentTarget.firstElementChild)
  }

  disconnect() {
    this.resizeObserver.disconnect()
  }

  processResizeEntries(entries, self) {
    entries.forEach(entry => {
      if(this.isOpenValue) {
        this.contentTarget.style.maxHeight = `${entry.target.scrollHeight}px`
      }
    })
  }

  toggle() {
    if (this.isOpenValue) {
      this.chevronTarget.classList.remove('rotate-180')
      this.contentTarget.style.maxHeight = ""
    } else {
      this.open()
    }

    this.isOpenValue = !this.isOpenValue
  }

  open() {
    this.chevronTarget.classList.add('rotate-180')
    this.setMaxHeight()
  }

  setMaxHeight() {
    let content = this.contentTarget
    content.style.maxHeight = `${content.scrollHeight}px`
  }
}