import { application } from "../application";

import ToggleController from "./toggle-controller.js";
import NewsletterController from "./newsletter-controller.js";
import LazyLoaderController from "./lazy-loader-controller.js";
import PaginationController from "./pagination-controller.js";
import ButtonLoaderController from "./button-loader-controller.js";
import SmoothScrollController from "./smooth-scroll-controller.js"; // Only license
import StickyController from "./sticky-controller.js"; // Only license
import FlashMessageController from "./flash-message-controller.js";
//import GumroadController from "./gumroad-controller.js";
import TabsController from "./tabs-controller.js";
import ClassTogglerController from "./class-toggler-controller.js";
import AccordionController from "./accordion-controller.js";
import SidebarController from "./sidebar-controller";
import ModalFormController from "./modal-form-controller";
import MasonryController from "./masonry-controller";
import FiltersController from "./filters-controller";
import BannerController from "./banner-controller";

application.register("toggle", ToggleController);
application.register("newsletter", NewsletterController);
application.register("lazy-loader", LazyLoaderController);
application.register("pagination", PaginationController);
application.register("button-loader", ButtonLoaderController);
application.register("smooth-scroll", SmoothScrollController);
application.register("sticky", StickyController);
application.register("flash-message", FlashMessageController);
//application.register("gumroad", GumroadController);
application.register("tabs", TabsController);
application.register("class-toggler", ClassTogglerController);
application.register("accordion", AccordionController);
application.register("sidebar", SidebarController);
application.register("modal-form", ModalFormController);
application.register("masonry", MasonryController);
application.register("filters", FiltersController);
application.register("banner", BannerController);
