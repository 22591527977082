import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['text', 'loader', 'button']
  static classes = ['hidden']

  load() {
    this.buttonTarget.style.width = `${this.buttonTarget.offsetWidth}px`
    this.buttonTarget.style.height = `${this.buttonTarget.offsetHeight}px`
    this.buttonTarget.disabled = true
    this.textTarget.classList.add(this.hiddenClass)
    this.loaderTarget.classList.remove(this.hiddenClass)
  }

  unload() {
    this.buttonTarget.disabled = false
    this.loaderTarget.classList.add(this.hiddenClass)
    this.textTarget.classList.remove(this.hiddenClass)
    this.buttonTarget.style.width = ''
    this.buttonTarget.style.height = ''
  }
}
