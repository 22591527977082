import FlexMasonry from 'flexmasonry/src/flexmasonry.js';
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  connect() {
    FlexMasonry.init([this.element], {
      responsive: true,
      /*
      * A list of how many columns should be shown at different responsive
      * breakpoints, defined by media queries.
      */
      breakpointCols: {
        'min-width: 1280px': 2,
      },
      numCols: 1,
    });
  }

  reinitialize() {

    let breakEls = this.element.querySelectorAll("div.flexmasonry-break")
    breakEls.forEach(function(breakEl) {
      breakEl.remove()
    });

    FlexMasonry.destroyAll()
    FlexMasonry.init([this.element], {
      responsive: true,
      /*
      * A list of how many columns should be shown at different responsive
      * breakpoints, defined by media queries.
      */
      breakpointCols: {
        'min-width: 1280px': 2,
      },
      numCols: 1,
    })
  }

  disconnect() {
    FlexMasonry.destroyAll()
  }
}
